// project import
import admin from './admin';
import report from './report';
import specialist from './specialist';
import data from './data';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [specialist, admin, data, report]
};

export default menuItems;
