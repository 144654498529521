import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { ApiMethodsEnum } from 'store/constant';
import { KeyedObject } from 'types';
import { INote } from 'types/note';
import { ILearner, IPasswordReset, ISendPasswordResetEmail, ISession, IUser } from 'types/users';
import { IAccountStatus } from 'types/users/account';

import axios from 'utils/axios';
import { capitalize } from 'utils/helpers';

export type Builder = EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    string,
    string
>;
export const endpoints =
    <T extends IUser | ISession | INote | ILearner>(PATH: string) =>
    (builder: Builder) => ({
        getBySId: builder.query<T[], string>({
            query: (sId) => ({
                url: `/${PATH}${sId ? `?sId=${sId}${PATH === 'sessions' ? '' : '&status=Active'}` : ''}`,
                method: ApiMethodsEnum.GET
            }),
            transformResponse: (res: T[]) => res || [],
            providesTags: [PATH]
        }),
        getByRelationId: builder.query<T[], { relType: 'lSRelId' | 'lId' | 'sId'; id: string }>({
            query: ({ relType, id }) => ({ url: `/${PATH}${relType ? `?${relType}=${id}` : ''}`, method: ApiMethodsEnum.GET }),
            transformResponse: (res: T[]) => res || [],
            providesTags: [PATH]
        }),
        getBysIdAndlId: builder.query<T[], { lId: string; sId?: string }>({
            query: ({ lId, sId }) => ({ url: `/${PATH}?lId=${lId}${sId ? `&sId=${sId}` : ''}`, method: ApiMethodsEnum.GET }),
            transformResponse: (res: T[]) => res || [],
            providesTags: [PATH]
        }),
        get: builder.query<T[], string | void>({
            query: (sId) => ({
                url: sId ? `/specialists?id=${sId}&status=Active&inject=true` : `/${PATH}`,
                method: ApiMethodsEnum.GET
            }),
            transformResponse: (res: T[]) => res || [],
            providesTags: [PATH]
        }),
        getInjected: builder.query<T[], string | void>({
            query: (sId) => ({
                url: sId ? `/specialists?id=${sId}&status=Active&inject=true` : `/${PATH}?inject=true`,
                method: ApiMethodsEnum.GET
            }),
            transformResponse: (res: T[]) => res || [],
            providesTags: [PATH]
        }),
        getActiveSpecialists: builder.query<T[], string | void>({
            query: (sId) => ({
                url: `/${PATH}${sId && '?status=Active'}${`${sId ? '&' : '?'}inject=true`}`,
                method: ApiMethodsEnum.GET
            }),
            transformResponse: (res: T[]) => res || [],
            providesTags: [PATH]
        }),
        getById: builder.query<T, { id: string }>({
            query: ({ id }) => ({
                url: `/${PATH}?id=${id}&inject=true`,
                method: ApiMethodsEnum.GET
            }),
            transformResponse: (res: T[], meta, arg) => res?.[0],
            providesTags: (result, error, { id }) => [{ type: PATH, id }]
        }),
        getByName: builder.query<T[], string>({
            query: (name: string) => ({ url: `/${PATH}?fullName=${name}`, method: ApiMethodsEnum.GET }),
            transformResponse: (res: T[]) => res || []
            // providesTags: (result, error, name) => [{ type: PATH, id: name }]
        }),
        getBySIdAndName: builder.query<T[], { sId: string; name?: string }>({
            query: ({ sId, name }) => ({
                url: `/specialists/${sId}/${PATH}${name && `?lFullName=${name}`}`,
                method: ApiMethodsEnum.GET
            }),
            transformResponse: (res: T[]) => res || []
        }),
        add: builder.mutation<T, Partial<T>>({
            query: (obj: Partial<T>) => ({
                url: `/${PATH}`,
                method: ApiMethodsEnum.POST,
                body: obj
            }),
            invalidatesTags: (result) => (result ? [PATH] : [])
        }),
        addByVersion: builder.mutation<T, Partial<T> & { version?: string }>({
            query: (obj: Partial<T> & { version?: string }) => ({
                url: `/${PATH}/${obj.version?.toLowerCase()}`,
                method: ApiMethodsEnum.POST,
                body: obj
            }),
            invalidatesTags: (result) => (result ? [PATH] : [])
        }),
        update: builder.mutation<T, Partial<T> & Pick<T, 'id'>>({
            query: (obj: Partial<T>) => ({
                url: `/${PATH}/${obj.id}`,
                method: ApiMethodsEnum.PUT,
                body: obj
            }),
            invalidatesTags: (result) => (result ? [PATH] : [])
        }),
        delete: builder.mutation({
            query: (id: string) => ({
                url: `/${PATH}/${id}`,
                method: ApiMethodsEnum.DELETE,
                body: { deleted: true }
            }),
            invalidatesTags: [PATH]
        }),
        activationStatus: builder.mutation({
            query: ({ id, status }) => ({
                url: `/${PATH}/${id}`,
                method: ApiMethodsEnum.DELETE,
                body: { deleted: status }
            }),
            invalidatesTags: [PATH]
        }),
        sendInvite: builder.mutation({
            query: (id: string) => ({
                url: `/${PATH}/${id}/invite`,
                method: ApiMethodsEnum.POST,
                body: id
            })
        }),
        sendPasswordResetEmail: builder.mutation({
            query: (passObj: ISendPasswordResetEmail) => ({
                url: `security/password/otp/email`,
                method: ApiMethodsEnum.POST,
                body: passObj
            })
        }),
        updatePassword: builder.mutation<any, IPasswordReset>({
            query: (passObj: IPasswordReset) => ({
                url: `/security/password/update`,
                method: ApiMethodsEnum.PUT,
                body: passObj
            })
        }),
        getAccountStatus: builder.query<IAccountStatus, string>({
            query: (id: string) => ({
                url: `/security/${capitalize(PATH.slice(0, -1))}/${id}/account-status`,
                method: ApiMethodsEnum.GET
            })
        }),
        getByIdOptSId: builder.query<T, { id: string; sId: string }>({
            query: ({ id, sId }) => ({ url: `/${PATH}?id=${id}${sId && `&sId=${sId}`}`, method: ApiMethodsEnum.GET }),
            transformResponse: (res: T[], meta, arg) => res?.[0],
            providesTags: (result, error, { id }) => [{ type: PATH, id }]
        }),
        getByIds: builder.query<T[], { ids: string[] }>({
            query: ({ ids }) => ({
                url: `/${PATH}${ids.map((id, ind) => `${ind === 0 ? '?' : '&'}ids=${id}`).join('')}&inject=-true`,
                method: ApiMethodsEnum.GET
            }),
            transformResponse: (res: T[]) => res || [],
            providesTags: [PATH]
        }),
        getByParams: builder.query<T[], Partial<T>>({
            query: (paramsObj: KeyedObject) => ({
                url: `/${PATH}?${new URLSearchParams(paramsObj).toString()}`,
                method: ApiMethodsEnum.GET
            }),
            transformResponse: (res: T[]) => res, // .filter((x) => (x as INote).topLevel),
            providesTags: [PATH.split('/').slice(-1)[0]]
        })
    });

export const dashboaardEndpoints =
    <T>(PATH: string) =>
    (builder: Builder) => ({
        get: builder.query<T, { schedule: string }>({
            query: ({ schedule = 'Every Day' }) => ({ url: `/${PATH}?all=true&schedule=${schedule}`, method: ApiMethodsEnum.GET }),
            providesTags: [PATH.split('/').slice(-1)[0]]
        })
    });

const ApiSlice = createApi({
    reducerPath: 'learnfully',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_LAGUNA_SECA_API_URL,
        prepareHeaders: (headers) => {
            headers.set('authorization', `${axios.defaults.headers.common.Authorization}`);
            return headers;
        }
    }),
    tagTypes: [
        'admins',
        'specialists',
        'caregivers',
        'learners',
        'sessions',
        'dashboard',
        'notes',
        'files',
        'recurring-events',
        'groups',
        'calendar-events'
    ],
    endpoints: (build) => ({})
});

export default ApiSlice;
