// third-party
import { configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, TypedUseSelectorHook } from 'react-redux';

// project imports
import rootReducer from './reducer';
import UsersSlice from './api-slices/api';
import axios from 'utils/axios';
import jwtDecode from 'jwt-decode';
import { JWTUser } from 'types/auth';

// ==============================|| REDUX - MAIN STORE ||============================== //

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        if (action.payload?.status === 401) {
            const serviceToken = localStorage.getItem('jwt') || '';
            const user: JWTUser = jwtDecode(serviceToken);
            localStorage.removeItem('jwt');
            delete axios.defaults.headers.common.Authorization;
            window.location.replace(`/login/${user?.userType?.toLowerCase() || 'specialist'}`);
            next({
                type: 'LOGOUT'
            });
        }
    }

    return next(action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(UsersSlice.middleware).concat(rtkQueryErrorLogger)
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, useSelector, useDispatch };
