// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import UsersSlice from './api-slices/api';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    [UsersSlice.reducerPath]: UsersSlice.reducer,
    snackbar: snackbarReducer,
    menu: menuReducer
});

export default reducer;
