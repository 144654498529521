// third-party
import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useReducer } from 'react';
import accountReducer from 'store/accountReducer';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';

// types
import { InitialLoginContextProps, IPasswordResetOTP, JWTContextType, JWTUser, UserTypeEnum } from 'types/auth';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// constant
const initialState: InitialLoginContextProps = {
    isAdmin: false,
    isInitialized: false,
    isLoggedIn: false,
    isSpecialist: false,
    specialistId: '',
    user: null,
    userId: '',
    userType: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: JWTUser = jwtDecode(serviceToken);
    return decoded.exp > Math.round(Date.now() / 1000);
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('jwt', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('jwt');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('jwt');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    const user: JWTUser = jwtDecode(serviceToken);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isAdmin: user.userType === UserTypeEnum.Admin,
                            isLoggedIn: true,
                            isSpecialist: user.userType === UserTypeEnum.Specialist,
                            specialistId: user.userType === UserTypeEnum.Specialist ? user.aud : '',
                            user,
                            userId: user.aud,
                            userType: user.userType
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (email: string, password: string, userType: UserTypeEnum) => {
        const response = await axios.post(`/security/login`, { email, password, userType });
        const { jwt } = response.data;
        const user: JWTUser = jwtDecode(jwt);
        setSession(jwt);
        dispatch({
            type: LOGIN,
            payload: {
                isAdmin: user.userType === UserTypeEnum.Admin,
                isLoggedIn: true,
                isSpecialist: user.userType === UserTypeEnum.Specialist,
                specialistId: user.userType === UserTypeEnum.Specialist ? user.aud : '',
                user,
                userId: user.aud || '',
                userType: user.userType || null
            }
        });
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (data: IPasswordResetOTP) => {
        await axios.post(`/security/password/reset`, data);
        setSession(null);
    };

    const sendOTP = async (email: string, userType: UserTypeEnum) => {
        await axios.post(`/security/password/otp/email`, { email, userType });
        logout();
    };

    const sendInvite = async (userId: string, userType: UserTypeEnum) => {
        await axios.post(`/security/invite`, { userId, userType });
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout, resetPassword, sendOTP, sendInvite }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
