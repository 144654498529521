// third-party
// assets
import { IconAccessible, IconDeviceTv, IconFriends, IconHeartHandshake, IconSchool, IconUsers, IconAffiliate } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { SideMenuProps } from 'types/menu';

// constant
const icons = {
    IconAccessible,
    IconDeviceTv,
    IconFriends,
    IconHeartHandshake,
    IconSchool,
    IconUsers,
    IconAffiliate
};

const specialist: SideMenuProps = {
    id: 'specialist',
    type: 'group',
    title: <FormattedMessage id="specialist" />,
    children: [
        {
            id: 'learners',
            title: <FormattedMessage id="learners" />,
            type: 'item',
            icon: icons.IconSchool,
            url: '/learners'
        },
        {
            id: 'caregivers',
            title: <FormattedMessage id="caregivers" />,
            type: 'item',
            url: '/caregivers',
            icon: icons.IconHeartHandshake
        },
        {
            id: 'specialists',
            title: <FormattedMessage id="specialists" />,
            type: 'item',
            url: '/specialists',
            icon: icons.IconUsers
        },
        {
            id: 'learner-specialist',
            title: <FormattedMessage id="pairs" />,
            type: 'item',
            url: '/relationships/learner-specialist',
            icon: icons.IconFriends
        },
        {
            id: 'sessions',
            title: <FormattedMessage id="sessions" />,
            type: 'item',
            url: '/sessions',
            icon: icons.IconDeviceTv
        },
        {
            id: 'groups',
            title: <FormattedMessage id="groups" />,
            type: 'item',
            url: '/groups',
            icon: icons.IconAffiliate
        }
    ]
};

export default specialist;
