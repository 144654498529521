import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const AdminForm = Loadable(lazy(() => import('views/forms/admin/admin-form')));
const AdminIndex = Loadable(lazy(() => import('views/forms/admin')));
const AdminSettings = Loadable(lazy(() => import('views/forms/admin/admin-settings')));
const AdminsPage = Loadable(lazy(() => import('views/users/admins')));
const CaregiverForm = Loadable(lazy(() => import('views/forms/caregiver/caregiver-form')));
const CaregiverIndex = Loadable(lazy(() => import('views/forms/caregiver')));
const CaregiverNotes = Loadable(lazy(() => import('views/forms/caregiver/caregiver-notes')));
const CaregiverRelationships = Loadable(lazy(() => import('views/forms/caregiver/caregiver-relationships')));
const CaregiversPage = Loadable(lazy(() => import('views/users/caregivers')));
// const CreateAdmin = Loadable(lazy(() => import('views/forms/admin')));
const CreateFile = Loadable(lazy(() => import('views/forms/file/index')));
const CreateLearner = Loadable(lazy(() => import('views/forms/learner')));
const CreateLearnerSpecialist = Loadable(lazy(() => import('views/relationships/learner-specialist/learner-specialist-create')));
const CreateSessions = Loadable(lazy(() => import('views/data/create/session-create')));
const CreateGroup = Loadable(lazy(() => import('views/relationships/group-specialist-learners/group-create')));
const CreateMultipleSessions = Loadable(lazy(() => import('views/data/create/group-session-create')));
const GroupSessionAttendance = Loadable(lazy(() => import('views/data/create/group-session-attendance')));
// const CreateSpecialist = Loadable(lazy(() => import('views/forms/specialist')));
const FilesPage = Loadable(lazy(() => import('views/files/files-page')));
const K5LiteracyReportView = Loadable(lazy(() => import('views/reports/k-5-literacy')));
const LearnerCaregiver = Loadable(lazy(() => import('views/relationships/learner-caregiver')));
const LearnerCaregiverRelCreate = Loadable(lazy(() => import('views/relationships/create/learner-caregiver-create')));
const LearnerForm = Loadable(lazy(() => import('views/forms/learner/learner-form')));
const LearnerCalendar = Loadable(lazy(() => import('views/forms/learner/learner-calendar')));
const LearnerNotes = Loadable(lazy(() => import('views/forms/learner/learner-notes')));
const LearnerPairs = Loadable(lazy(() => import('views/forms/learner/learner-pairs')));
const LearnerRelationships = Loadable(lazy(() => import('views/forms/learner/learner-relationships')));
const LearnerSessions = Loadable(lazy(() => import('views/forms/learner/learner-sessions')));
// const LearnerSpecialist = Loadable(lazy(() => import('views/relationships/learner-specialist')));
const LearnerSpecialistForm = Loadable(lazy(() => import('views/relationships/learner-specialist/learner-specialist-form')));
const LearnerSpecialistIndex = Loadable(lazy(() => import('views/relationships/learner-specialist')));
const LearnerSpecialistNotes = Loadable(lazy(() => import('views/relationships/learner-specialist/learner-specialist-notes')));
const LearnerSpecialistSchedule = Loadable(lazy(() => import('views/relationships/learner-specialist/learner-specialist-schedule')));
const LearnerSpecialistSessions = Loadable(lazy(() => import('views/relationships/learner-specialist/learner-specialist-sessions')));
const LearnersPage = Loadable(lazy(() => import('views/users/learners')));
const GroupsPage = Loadable(lazy(() => import('views/relationships/group-specialist-learners/groups-page')));
const GroupIndex = Loadable(lazy(() => import('views/relationships/group-specialist-learners')));
const GroupView = Loadable(lazy(() => import('views/relationships/group-specialist-learners/group-view')));
const GroupNotes = Loadable(lazy(() => import('views/relationships/group-specialist-learners/group-notes')));
const GroupSchedule = Loadable(lazy(() => import('views/relationships/group-specialist-learners/group-schedule')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const Metrics = Loadable(lazy(() => import('views/data/metrics')));
const ParentCefiReportView = Loadable(lazy(() => import('views/reports/parent-cefi')));
const RelationshipsPage = Loadable(lazy(() => import('views/relationships/relationships')));
const Session = Loadable(lazy(() => import('views/data/session')));
const SessionLearnerSpecialist = Loadable(lazy(() => import('views/data/create/session-learner-specialist')));
const SessionPrograms = Loadable(lazy(() => import('views/data/create/session-programs')));
const Sessions = Loadable(lazy(() => import('views/data/sessions')));
const SpecialistForm = Loadable(lazy(() => import('views/forms/specialist/specialist-form')));
const SpecialistIndex = Loadable(lazy(() => import('views/forms/specialist')));
const SpecialistLearners = Loadable(lazy(() => import('views/forms/specialist/specialist-learners')));
const SpecialistNotes = Loadable(lazy(() => import('views/forms/specialist/specialist-notes')));
const SpecialistPrograms = Loadable(lazy(() => import('views/relationships/programs')));
const SpecialistSessions = Loadable(lazy(() => import('views/forms/specialist/specialist-sessions')));
const SpecialistSettings = Loadable(lazy(() => import('views/forms/specialist/specialist-settings')));
const SpecialistsPage = Loadable(lazy(() => import('views/users/specialists')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <AdminsPage />
        },
        {
            path: '/admins',
            element: <AdminsPage />
        },
        {
            path: '/admins/:id',
            element: <AdminIndex />,
            children: [
                {
                    path: '/admins/:id/profile',
                    element: <AdminForm />
                },
                {
                    path: '/admins/:id/account-settings',
                    element: <AdminSettings />
                }
            ]
        },
        {
            path: '/caregivers',
            element: <CaregiversPage />
        },
        {
            path: '/caregivers/:id',
            element: <CaregiverIndex />,
            children: [
                {
                    path: '/caregivers/:id/profile',
                    element: <CaregiverForm />
                },
                {
                    path: '/caregivers/:id/relationships',
                    element: <CaregiverRelationships />
                },
                {
                    path: '/caregivers/:id/notes',
                    element: <CaregiverNotes />
                }
            ]
        },
        {
            path: '/learners',
            element: <LearnersPage />
        },
        {
            path: '/learners/:id',
            element: <CreateLearner />,
            children: [
                {
                    path: '/learners/:id/profile',
                    element: <LearnerForm />
                },
                {
                    path: '/learners/:id/sessions',
                    element: <LearnerSessions />
                },
                {
                    path: '/learners/:id/pairs',
                    element: <LearnerPairs />
                },
                {
                    path: '/learners/:id/relationships',
                    element: <LearnerRelationships />
                },
                {
                    path: '/learners/:id/files',
                    element: <FilesPage />
                },
                {
                    path: '/learners/:id/calendar',
                    element: <LearnerCalendar />
                },
                {
                    path: '/learners/:id/notes',
                    element: <LearnerNotes />
                }
            ]
        },
        {
            path: '/files/create/1',
            element: <CreateFile />
        },
        {
            path: '/specialists',
            element: <SpecialistsPage />
        },
        {
            path: '/specialists/:id',
            element: <SpecialistIndex />,
            children: [
                {
                    path: '/specialists/:id/profile',
                    element: <SpecialistForm />
                },
                {
                    path: '/specialists/:id/sessions',
                    element: <SpecialistSessions />
                },
                {
                    path: '/specialists/:id/pairs',
                    element: <SpecialistLearners />
                },
                {
                    path: '/specialists/:id/account-settings',
                    element: <SpecialistSettings />
                },
                {
                    path: '/specialists/:id/notes',
                    element: <SpecialistNotes />
                }
            ]
        },
        {
            path: '/sessions',
            element: <Sessions />
        },
        {
            path: '/sessions/create/1',
            element: <SessionLearnerSpecialist />
        },
        {
            path: '/sessions/create/2',
            element: <SessionPrograms />
        },
        {
            path: '/sessions/V01/:id',
            element: <Session />
        },
        {
            path: '/sessions/V01/create/3',
            element: <CreateSessions />
        },
        {
            path: '/metrics',
            element: <Metrics />
        },
        {
            path: '/relationships/learner-specialist',
            element: <SpecialistPrograms />
        },
        {
            path: '/relationships/learner-specialist/create/1',
            element: <CreateLearnerSpecialist />
        },
        {
            path: '/relationships/learner-specialist/create/2',
            element: <LearnerSpecialistForm />
        },
        {
            path: '/relationships/learner-specialist/:id',
            element: <LearnerSpecialistIndex />,
            children: [
                {
                    path: '/relationships/learner-specialist/:id/profile',
                    element: <LearnerSpecialistForm />
                },
                {
                    path: '/relationships/learner-specialist/:id/sessions',
                    element: <LearnerSpecialistSessions />
                },
                {
                    path: '/relationships/learner-specialist/:id/schedule',
                    element: <LearnerSpecialistSchedule />
                },
                {
                    path: '/relationships/learner-specialist/:id/notes',
                    element: <LearnerSpecialistNotes />
                }
            ]
        },
        {
            path: '/relationships/learner-caregiver',
            element: <RelationshipsPage />
        },
        {
            path: '/relationships/learner-caregiver/create/1',
            element: <LearnerCaregiver />
        },
        {
            path: '/relationships/learner-caregiver/create/2',
            element: <LearnerCaregiverRelCreate />
        },
        {
            path: '/relationships/learner-caregiver/:id',
            element: <LearnerCaregiverRelCreate />
        },
        {
            path: '/groups',
            element: <GroupsPage />
        },
        {
            path: '/groups/:id',
            element: <GroupIndex />,
            children: [
                {
                    path: '/groups/:id/profile',
                    element: <GroupView />
                },
                {
                    path: '/groups/:id/schedule',
                    element: <GroupSchedule />
                },
                {
                    path: '/groups/:id/notes',
                    element: <GroupNotes />
                }
            ]
        },
        {
            path: '/groups/create/1',
            element: <CreateGroup />
        },
        {
            path: '/sessions/group/create/1',
            element: <GroupSessionAttendance />
        },
        {
            path: '/sessions/group/create/2',
            element: <CreateMultipleSessions />
        },
        {
            path: '/reports/parent-cefi',
            element: <ParentCefiReportView />
        },
        {
            path: '/reports/k-5-literacy',
            element: <K5LiteracyReportView />
        },
        {
            path: '/*',
            element: <MaintenanceError />
        }
    ]
};

export default MainRoutes;
