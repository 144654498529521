// third-party
// assets
import { IconFriends, IconHeartHandshake, IconLink, IconSchool, IconUserPlus, IconUsers, IconAffiliate } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import { SideMenuProps } from 'types/menu';

// constant
const icons = {
    IconFriends,
    IconHeartHandshake,
    IconLink,
    IconSchool,
    IconUserPlus,
    IconUsers,
    IconAffiliate
};

const admin: SideMenuProps = {
    id: 'admin',
    type: 'group',
    title: <FormattedMessage id="admin" />,
    children: [
        {
            id: 'learners',
            title: <FormattedMessage id="learners" />,
            type: 'item',
            icon: icons.IconSchool,
            url: '/learners'
        },
        {
            id: 'caregivers',
            title: <FormattedMessage id="caregivers" />,
            type: 'item',
            url: '/caregivers',
            icon: icons.IconHeartHandshake
        },
        {
            id: 'specialists',
            title: <FormattedMessage id="specialists" />,
            type: 'item',
            url: '/specialists',
            icon: icons.IconUsers
        },
        {
            id: 'learner-specialist',
            title: <FormattedMessage id="pairs" />,
            type: 'item',
            url: '/relationships/learner-specialist',
            icon: icons.IconFriends
        },
        {
            id: 'learner-caregiver',
            title: <FormattedMessage id="relationships" />,
            type: 'item',
            url: '/relationships/learner-caregiver',
            icon: icons.IconLink
        },
        {
            id: 'admins',
            title: <FormattedMessage id="admins" />,
            type: 'item',
            url: '/admins',
            icon: icons.IconUserPlus
        },
        {
            id: 'groups',
            title: <FormattedMessage id="groups" />,
            type: 'item',
            url: '/groups',
            icon: icons.IconAffiliate
        }
    ]
};

export default admin;
