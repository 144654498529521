// third-party
import { FormattedMessage } from 'react-intl';
import { SideMenuProps } from 'types/menu';

// assets
import { IconChartBar, IconReportAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconChartBar,
    IconReportAnalytics
};

const report: SideMenuProps = {
    id: 'report',
    type: 'group',
    title: <FormattedMessage id="reports" />,
    children: [
        {
            id: 'parent-cefi',
            title: <FormattedMessage id="parent-cefi" />,
            type: 'item',
            icon: icons.IconChartBar,
            url: '/reports/parent-cefi'
        },
        {
            id: 'k-5-literacy',
            title: <FormattedMessage id="k5-literacy" />,
            type: 'item',
            url: '/reports/k-5-literacy',
            icon: icons.IconReportAnalytics
        }
    ]
};

export default report;
