import { UserTypeEnum } from 'types/auth';

export interface IAddress {
    city?: string;
    numberAndStreet?: string;
    state?: string;
    unit?: string;
    zip?: string;
    plus?: string;
}

export enum StatusEnum {
    Active = 'Active',
    Inactive = 'Inactive',
    New = 'New',
    OnHold = 'On-Hold'
}

export enum GenderEnum {
    Male = 'Male',
    Female = 'Female',
    NonBinary = 'Non-Binary'
}

export enum LevelEnum {
    Super = 'Super',
    Regular = 'Regular'
}

export enum ProgramFormatEnum {
    InPerson = 'In-Person',
    LearnfullyCenter = 'Learnfully center',
    Online = 'Online'
}

export enum RoleEnum {
    Guardian = 'Guardian',
    Other = 'Other',
    Parent = 'Parent',
    StepParent = 'Step-Parent'
}

export enum ProgramEnum {
    EducationTherapyLiteracy = 'Education Therapy - Literacy',
    EducationTherapyMath = 'Education Therapy - Math',
    ExecutiveFunctioning = 'Executive Functioning'
}

export enum SessionRollCallEnum {
    AsScheduled = 'As Scheduled',
    CancelledUnder24H = 'Cancelled Under 24H',
    Rescheduled = 'Rescheduled'
}

// This enum is used to strip the sub-program name from the v01 data properties
// so that each property name / checkbox label can be generated programmatically
export enum SubProgramPropertyPrefixes {
    comprehension = 'comprehension',
    decoding = 'decoding',
    executiveFunction = 'executiveFunction',
    math = 'math',
    reading = 'reading',
    writing = 'writing'
}

export enum TypeEnum {
    Seasonal = 'Seasonal',
    Monthly = 'Monthly'
}

export enum DocumentTypeEnum {
    LCRel = 'LCRel',
    LSRel = 'LSRel',
    Session = 'Session',
    File = 'File',
    Note = 'Note',
    Group = 'Group'
}

export type RelationshipTypeEnum = DocumentTypeEnum | UserTypeEnum;

export interface IPasswordReset {
    userId: string;
    password: string;
    newPassword1: string;
    newPassword2: string;
    userType: UserTypeEnum;
}

export interface ISendPasswordResetEmail {
    email: string;
    userType: UserTypeEnum;
}
