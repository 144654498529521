import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));
const AuthCheckMail = Loadable(lazy(() => import('views/pages/authentication/CheckMail3')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword3')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <Navigate to="/login/specialist" replace />
        },
        {
            path: '/login/specialist',
            element: <AuthLogin />
        },
        {
            path: '/login/admin',
            element: <AuthLogin />
        },
        {
            path: '/forgot-password/1/specialist',
            element: <AuthForgotPassword />
        },
        {
            path: '/forgot-password/2/specialist',
            element: <AuthCheckMail />
        },
        {
            path: '/forgot-password/3/specialist',
            element: <AuthResetPassword />
        },
        {
            path: '/forgot-password/1/admin',
            element: <AuthForgotPassword />
        },
        {
            path: '/forgot-password/2/admin',
            element: <AuthCheckMail />
        },
        {
            path: '/forgot-password/3/admin',
            element: <AuthResetPassword />
        },
        {
            path: '/set-password/1/specialist',
            element: <AuthForgotPassword />
        },
        {
            path: '/set-password/2/specialist',
            element: <AuthCheckMail />
        },
        {
            path: '/set-password/3/specialist',
            element: <AuthResetPassword />
        },
        {
            path: '/set-password/1/admin',
            element: <AuthForgotPassword />
        },
        {
            path: '/set-password/2/admin',
            element: <AuthCheckMail />
        },
        {
            path: '/set-password/3/admin',
            element: <AuthResetPassword />
        },
        {
            path: '/reset-password/1/specialist',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset-password/2/specialist',
            element: <AuthCheckMail />
        },
        {
            path: '/reset-password/3/specialist',
            element: <AuthResetPassword />
        },
        {
            path: '/reset-password/1/admin',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset-password/2/admin',
            element: <AuthCheckMail />
        },
        {
            path: '/reset-password/3/admin',
            element: <AuthResetPassword />
        }
    ]
};

export default LoginRoutes;
