import { memo, useEffect } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';
import { useDispatch } from 'store';
import { useLocation } from 'react-router-dom';
import { activeItem } from 'store/slices/menu';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { isSpecialist } = useAuth();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const items = isSpecialist ? menuItem.items.slice(0, 1) : menuItem.items.slice(1);

    const menuItems: string[] = [];
    items.forEach((group) => {
        group.children?.forEach((child) => menuItems.push(child.id as string));
    });

    // active menu item on page load
    useEffect(() => {
        const currentMenuItem = document.location.pathname
            .toString()
            .split('/')
            .find((id) => menuItems.includes(id));
        dispatch(activeItem([currentMenuItem]));

        // eslint-disable-next-line
    }, [pathname]);

    const navItems = items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
