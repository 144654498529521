export type JWTContextType = {
    isAdmin: boolean;
    isInitialized?: boolean;
    isLoggedIn: boolean;
    isSpecialist: boolean;
    login: (email: string, password: string, userType: UserTypeEnum) => Promise<void>;
    logout: () => void;
    resetPassword: (obj: IPasswordResetOTP) => Promise<void>;
    sendInvite: (userId: string, userType: UserTypeEnum) => Promise<void>;
    sendOTP: (email: string, userType: UserTypeEnum) => Promise<void>;
    specialistId: string;
    user?: JWTUser | null | undefined;
    userId: string;
    userType: UserTypeEnum | null;
};

export enum UserTypeEnum {
    Admin = 'Admin',
    Caregiver = 'Caregiver',
    Learner = 'Learner',
    Specialist = 'Specialist'
}

export interface InitialLoginContextProps {
    isAdmin: boolean;
    isInitialized?: boolean;
    isLoggedIn: boolean;
    isSpecialist: boolean;
    specialistId: string;
    user?: JWTUser | null | undefined;
    userId: string;
    userType: UserTypeEnum | null;
}

export interface IPasswordResetOTP {
    email: string;
    newPassword1: string;
    newPassword2: string;
    otpCode: string;
    otpCodeExpiresAt: string;
    userType: UserTypeEnum;
}

export type JWTUser = {
    aud: string;
    email: string;
    exp: number;
    firstName: string;
    fullName: string;
    iat: number;
    iss: string;
    jti: string;
    level?: 'Super' | 'Regular';
    name?: string;
    nbf: number;
    sub: string;
    userType: UserTypeEnum;
};
