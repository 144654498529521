// action - state management
import { InitialLoginContextProps, UserTypeEnum } from 'types/auth';
import { LOGIN, LOGOUT, REGISTER } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

interface AccountReducerActionProps {
    type: string;
    payload?: InitialLoginContextProps;
}

const initialState: InitialLoginContextProps = {
    isAdmin: false,
    isInitialized: false,
    isLoggedIn: false,
    isSpecialist: false,
    specialistId: '',
    user: null,
    userId: '',
    userType: null
};

const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload!;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload!;
            return {
                ...state,
                isAdmin: user?.userType === UserTypeEnum.Admin,
                isInitialized: true,
                isLoggedIn: true,
                isSpecialist: user?.userType === UserTypeEnum.Specialist,
                specialistId: user?.userType === UserTypeEnum.Specialist ? user.aud : '',
                user,
                userId: user?.aud || '',
                userType: user?.userType || null
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
