// third-party
import { FormattedMessage } from 'react-intl';
import { SideMenuProps } from 'types/menu';

// assets
import GradingIcon from '@mui/icons-material/Grading';
import PieChartIcon from '@mui/icons-material/PieChart';

const data: SideMenuProps = {
    id: 'data',
    type: 'group',
    title: <FormattedMessage id="data" />,
    children: [
        {
            id: 'sessions',
            title: <FormattedMessage id="sessions" />,
            type: 'item',
            icon: GradingIcon,
            url: '/sessions'
        },
        {
            id: 'metrics',
            title: <FormattedMessage id="metrics" />,
            type: 'item',
            icon: PieChartIcon,
            url: '/metrics'
        }
    ]
};

export default data;
