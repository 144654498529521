import { format } from 'date-fns';
import { JWTUser, UserTypeEnum } from 'types/auth';
import { ProgramEnum, SubProgramPropertyPrefixes } from 'types/users';

export const toISODate = (isoString?: string): string =>
    isoString && isoString.length >= 10 ? format(new Date(isoString), 'yyyy-MM-dd') : '';
export const toISOTime = (isoString: string): string => isoString.substring(10);
export const isValidDate = (date?: Date): boolean => {
    try {
        date?.toISOString();
        return !!date;
    } catch (e) {
        return false;
    }
};
export const adjustForTimezone = (isoString: string): Date => {
    const date = new Date(isoString);
    const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
export const formatPhone = (value: string) => {
    let formattedNumber;
    const { length } = value;

    const number = () => value.replace(/[^0-9.]+/g, '');
    const code = () => `(${number().slice(0, 3)})`;
    const six = () => `${code()} ${number().slice(3, 6)}`;
    const limiter = (start: number) => `${number().slice(start, Math.min(10, number().length))}`;
    if (length === 0) {
        formattedNumber = ``;
    } else if (length < 3) {
        formattedNumber = `(${number()}`;
    } else if (length === 4) {
        formattedNumber = `${code()} ${limiter(3)}`;
    } else if (length === 5) {
        formattedNumber = `${code().replace(')', '')}`;
    } else if (length > 5 && length < 9) {
        formattedNumber = `${code()} ${limiter(3)}`;
    } else if (length >= 10) {
        formattedNumber = `${six()}-${limiter(6)}`;
    }
    return formattedNumber;
};

export const formatNumber = (value: string, len: number) => {
    const { length } = value;
    const number = () => value.replace(/[^0-9.]+/g, '');
    const limiter = () => `${number().slice(0, Math.min(length, len))}`;
    return limiter();
};

export const formatDate = (isoDate?: string): string => {
    if (!isoDate || isoDate === '0001-01-01T00:00:00Z') {
        return 'N/A';
    }
    const date = new Date(isoDate);
    return date.toLocaleString();
};

export const commentDateFormat = (date: string) => {
    if (new Date().toDateString() === new Date(date).toDateString()) {
        return 'today';
    }
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (yesterday.toDateString() === new Date(date).toDateString()) {
        return 'yesterday';
    }
    return toISODate(date);
};

export const trimObjValues = (obj: any) => {
    Object.keys(obj).forEach((k: string) => {
        obj[k] = typeof obj[k] == 'string' ? obj[k].trim() : obj[k];
    });
};

export const initials = (name: string): string =>
    name
        .split(' ')
        .map((n) => n[0])
        .join('');
export const capitalize = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1);

export const startCase = (sentence: string): string => sentence.replace(/\w\S*/g, capitalize);

export const titleCase = (text: string): string => startCase(text.replace(/([A-Z])/g, ' $1'));

export const trimProgramName = (v01DataPropertyName: string): string => {
    for (const prefix in SubProgramPropertyPrefixes) {
        if (v01DataPropertyName.startsWith(prefix)) {
            return v01DataPropertyName.substring(prefix.length);
        }
    }

    return v01DataPropertyName;
};

export const THE_FUTURE = '3013-12-11';

export const possessive = (str: string) => {
    const APOSTROPHE_CHAR = "'";
    if (str === '') {
        return str;
    }
    const lastChar = str.slice(-1);
    const endOfWord = lastChar.toLowerCase() === 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`;
    return `${str}${endOfWord}`;
};

export const omitEmpty = (obj: any) => {
    const newObj = { ...obj };
    for (const key in newObj) {
        if (newObj[key] === '' || newObj[key] === undefined || newObj[key] === null) {
            delete newObj[key];
        }
    }
    return newObj;
};

export const debounce = <T extends (...args: any[]) => ReturnType<T>>(callback: T, timeout: number): ((...args: Parameters<T>) => void) => {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            callback(...args);
        }, timeout);
    };
};

export const camelize = (str: string): string =>
    str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });

export const programOptions = {
    [ProgramEnum.EducationTherapyLiteracy]: ['Decoding', 'Reading', 'Comprehension', 'Writing', 'Executive Functioning'],
    [ProgramEnum.EducationTherapyMath]: ['Math', 'Executive Functioning'],
    [ProgramEnum.ExecutiveFunctioning]: ['1 on 1', 'Small Group', 'Boot Camp'],
    '': []
};

export const rolesAllowed = (roles: string[], user?: JWTUser | null): boolean => {
    const { userType } = user || { userType: UserTypeEnum.Admin };
    // Todo: Add support for more types of users
    return roles.includes(userType);
};

export const filterCheckboxes = (key: string): Array<keyof V01Data> =>
    Object.keys(v01DataBooleans).filter((k) => {
        let starter = camelize(key);
        starter =
            starter === 'executiveFunctioning' || programOptions['Executive Functioning'].includes(key) ? 'executiveFunction' : starter;
        return k.startsWith(starter);
    }) as Array<keyof V01Data>;

export const getAllCheckboxes = (): string[] => Object.keys(v01DataBooleans) as Array<keyof V01Data>;

export type V01Data = Partial<typeof v01DataBooleans & typeof v01DataOther>;

const v01DataBooleans = {
    comprehension5ws: false,
    comprehensionChapterRecall: false,
    comprehensionCriticalThinking: false,
    comprehensionExecutiveFunctioning: false,
    comprehensionFollowingDirections: false,
    comprehensionMorphology: false,
    comprehensionOralExpressiveLanguage: false,
    comprehensionParagraphRecall: false,
    comprehensionSentenceRecall: false,
    comprehensionSummarizing: false,
    comprehensionVocabulary: false,
    comprehensionWholePageRecall: false,
    decodingBasicComplexAffixes: false,
    decodingDecodingFourOrMoreSyllableWords: false,
    decodingDecodingThreeSyllableWords: false,
    decodingDecodingTwoSyllableWords: false,
    decodingFiveAndSixSoundWords: false,
    decodingFourSoundWords: false,
    decodingOralReadingFluency: false,
    decodingPhonologicalProcessing: false,
    decodingRulesOfSyllabication: false,
    decodingSightWords: false,
    decodingSoundToSymbolRelationships: false,
    decodingSpellingEncoding: false,
    decodingThreeSoundWords: false,
    decodingTwoSoundWords: false,
    executiveFunctionAttention: false,
    executiveFunctionEmotionalControl: false,
    executiveFunctionFlexibility: false,
    executiveFunctionGoalSetting: false,
    executiveFunctionMetacognition: false,
    executiveFunctionOrganization: false,
    executiveFunctionPlanningAndPrioritizing: false,
    executiveFunctionResponseInhibition: false,
    executiveFunctionSelfMonitoring: false,
    executiveFunctionTaskInitiation: false,
    executiveFunctionTimeManagement: false,
    executiveFunctionWorkingMemory: false,
    mathAlgebra: false,
    mathCounting: false,
    mathDataAndStatistics: false,
    mathDecimals: false,
    mathExecutiveFunctioning: false,
    mathFractions: false,
    mathGeometry: false,
    mathMeasurementAndSize: false,
    mathMoney: false,
    mathNumberSense: false,
    mathPatterns: false,
    mathPreAlgebra: false,
    mathProblemSolving: false,
    mathShapesAndSpatialRelationships: false,
    mathSingleDigitAddition: false,
    mathSingleDigitDivision: false,
    mathSingleDigitMultiplication: false,
    mathSingleDigitSubtraction: false,
    mathSymbolRepresentation: false,
    mathTime: false,
    mathTwoPlusDigitAddition: false,
    mathTwoPlusDigitDivision: false,
    mathTwoPlusDigitMultiplication: false,
    mathTwoPlusDigitSubtraction: false,
    mathWordAndStoryProblems: false,
    readingContextualChapterReading: false,
    readingContextualParagraphReading: false,
    readingContextualSentenceReading: false,
    readingContextualWholePageReading: false,
    readingTextbookChapterReading: false,
    readingTextbookWholePageReading: false,
    studySkillsTestPrepExecutiveFunctioning: false,
    studySkillsTestPrepNoteTaking: false,
    studySkillsTestPrepStudyingStrategies: false,
    studySkillsTestPrepTestPreparationStrategies: false,
    writingCapitalization: false,
    writingDescriptiveWriting: false,
    writingEditing: false,
    writingExpositoryWriting: false,
    writingFormulatingBasicParagraph: false,
    writingFormulatingComplexParagraph: false,
    writingGrammar: false,
    writingGraphicOrganizers: false,
    writingGroupsOfSentences: false,
    writingHandwriting: false,
    writingKeyboarding: false,
    writingNarrativeWriting: false,
    writingNoteTaking: false,
    writingOrganization: false,
    writingPersuasiveWriting: false,
    writingPhrasesAndSingleSentences: false,
    writingPunctuation: false,
    writingSpellingConventions: false,
    writingSyntax: false,
    writingVoice: false
};

const v01DataOther = {
    // generic defaults
    focusAndGoalNotes: '',
    lEngagement: 5,
    lEngagementExtra: '',
    lFocus: 5,
    lFocusExtra: '',
    lMoodToday: '',
    lPerformance: 5,
    lPerformanceExtra: '',
    lPrepared: true,
    lProgress: 5,
    lProgressExtra: '',
    lSleepLastNight: 5,
    lSleepLastNightExtra: '',
    lStateToday: '',
    reinforcementExercises1: '',
    reinforcementExercises2: '',
    reinforcementExercises3: '',
    sessionAndReinforcementFeedback: '',
    sessionRollCall: '',
    sessionStartedOnTime: true
};

export const genericDefaults = [...Object.keys(v01DataOther), ...Object.keys(v01DataBooleans)].slice() as Array<keyof V01Data>;
